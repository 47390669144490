import client from "@/lib/axios/config.js"
import { projectAuth } from "../lib/firebase/config.js"

async function create(email, password, name) {
	const res = await projectAuth.createUserWithEmailAndPassword(email, password)
	if (!res) throw new Error("Could not complete signup!")
	await res.user.updateProfile({ displayName: name })
	await client.post("/client/users", {
		userID: res.user.uid, 
		email, 
		name,
	})
}

export default { create }