<template>
	<div class="loader animate-spin">
		
	</div>
</template>

<script>
export default {

}
</script>

<style>
.loader {
	border: 5px solid #d6d6d6; /* Light grey */
	border-top: 5px solid #555; /* Blue */
	border-radius: 50%;
	width: 25px;
	height: 25px;
}
</style>