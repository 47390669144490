<template>
<div>
	<div @click="toggleExpanded"
	class="pl-6 pr-2 py-2 flex items-center justify-between border hover:border-gray-600 cursor-pointer space-x-3 rounded-md shadow-lg bg-white">
		<h3><b v-if="episode.number && !episode.titleContainsNumber()">{{ episode.number }} - </b>{{ episode.shortTitle }}</h3>
		<button class="rounded-md p-3 bg-gray-700 text-gray-50
		hover:bg-gray-800 w-20">
			<span v-if="expanded">Close</span>
			<span v-else>Embed</span>
		</button>			
	</div>
	<div v-show="expanded" class="border shadow-inner rounded-md p-8 bg-gray-50">		
		<label class="block font-medium text-sm text-gray-700">Copy and paste this code snippet to your website</label>
		<div class="mt-2 flex space-x-1">
			<input type="text" readonly
			class="shadow-sm block w-full border-gray-300 rounded-md focus:ring-0 focus:border-gray-300"
			:value="embedScriptSingle"/>
			<button @click="copyTextToClipboard(embedScriptSingle)"
			class="rounded-md p-3 bg-gray-500 text-white
			hover:bg-gray-600">Copy</button>
		</div>
		<p class="mt-2 text-sm text-gray-500" id="email-description">Watch video: <a href="#" class="underline hover:text-gray-800">How to embed player code snippet on website?</a></p>		
	</div>
</div>
</template>

<script>
import { computed, ref } from "vue"
import { copyTextToClipboard } from "../lib/util/util"
import config from "../config"

export default {
	props: ["episode", "player"],
	setup(props) {
		const expanded = ref(false)
		const toggleExpanded = () => expanded.value = !expanded.value
		const embedScriptSingle = computed(() => `\<script src='${config.SERVER_BASE_URL}/static/player/player.js' data-podcastplayerco_type='single' data-podcastplayerco_key='${props.player?.id}.${props.episode?.guid}.${props.player?.podcastID}'>\<\/script\>`)

		return {
			embedScriptSingle, copyTextToClipboard, expanded, toggleExpanded
		}
	}
}
</script>

<style>

</style>