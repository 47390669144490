import client from "@/lib/axios/config.js"
import Player from "../classes/Player"

async function Create(player /*player*/) {
	try {
		await client.post("/client/players", player)
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function Update(player /*player*/) {
	try {
		await client.put(`/client/players/${player.id}`, player)
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function Delete(playerID) {
	try {
		await client.delete(`/client/players/${playerID}`)
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function ByUser() {
	const res = await client.get("/client/players")
	const players = []
	res.data.forEach(pData => {
		players.push(new Player({
			id: pData.playerID,
			name: pData.name,
			settings: pData.settings,
			type: pData.type,
			podcastID: pData.podcastID,
		}))
	})
	return players
}

export default { Create, Update, Delete, ByUser }