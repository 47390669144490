import { ref } from "vue"
import { sleep } from "../../lib/util/util.js"
import PlayerService from "../../services/PlayerService"

const error = ref(null)
const loading = ref(false)
const players = ref([])

const getPlayers = async () => {
	loading.value = true
	await sleep(1000)
	try {
		players.value = await PlayerService.ByUser()
		error.value = null
		loading.value = false
	} catch (err) {
		error.value = err.message
		loading.value = false
	}
}

const useGetPlayersByUser = () => {
	return { error, loading, players, getPlayers }
}

export default useGetPlayersByUser