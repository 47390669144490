import axios from "axios" 
import { projectAuth } from "../firebase/config.js"
import config from "../../config.js"

const client = axios.create({
	baseURL: `${config.SERVER_BASE_URL}/api`
})

client.interceptors.request.use(async request => {
	if (request.url.startsWith("/client")) {
		const token = await projectAuth.currentUser.getIdToken(false)
		request.headers['Authorization'] = `Bearer ${token}`	
	}	
	return request
})

export default client