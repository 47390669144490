<template>
<div class="w-28 bg-gray-700 overflow-y-auto h-screen fixed left-0">
	<div class="w-full py-6 flex flex-col items-center">
		<!-- LOGO -->
		<div class="flex-shrink-0 flex items-center">
			<img class="h-16 w-auto" src="@/assets/podplayer-icon-white.png" alt="Workflow" />
		</div>
		<!-- ITEMS -->
		<div class="flex-1 mt-6 w-full px-2 space-y-1">
			<router-link v-for="route in navigation" :key="route.name" :to="{ name: route.name }" :class="[route.name == currentRouteName ? 'bg-gray-800' : 'hover:bg-gray-800 hover:text-white', 'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium text-white']">			
				<component :is="route.icon" :class="[route.name == currentRouteName ? 'text-white' : 'group-hover:text-white', 'h-6 w-6']"/>
				<span class="mt-2">{{ route.name }}</span>
			</router-link>
		</div>
	</div>

	<div @click="handleLogout"
	class="absolute bottom-0 w-full py-3 flex justify-center items-center text-white bg-gray-800 hover:bg-gray-900 rounded cursor-pointer">
		<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="20px" viewBox="0 0 24 24" width="20px" fill="#FFFFFF"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z"/></g></svg>
		<span class="pl-1 text-sm font-medium">Logout</span>
	</div>
</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from "vue-router"
import useLogout from "../hooks/auth/useLogout"
import {	CogIcon, HomeIcon, QuestionMarkCircleIcon, MicrophoneIcon, LightningBoltIcon } from '@heroicons/vue/solid'

const navigation = [
	{ name: 'Dashboard', icon: HomeIcon, current: false },
	{ name: 'Podcasts', icon: MicrophoneIcon, current: false },
	{ name: 'Players', icon: LightningBoltIcon, current: true },
	{ name: 'Help', icon: QuestionMarkCircleIcon, current: false },
	// { name: 'Settings', icon: CogIcon, current: false },
]

export default {
	setup() {
		const route  = useRoute()
		const router = useRouter()
		const currentRouteName = computed(() => route.name)

		const { error, logout } = useLogout()
		async function handleLogout() {
			await logout()
			if (!error.value) router.push({ name: "Login" })
		}

		return { navigation, currentRouteName, handleLogout }
	},
}
</script>