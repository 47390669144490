import { ref } from "vue"
import PodcastService from "../../services/PodcastService"
import { sleep } from "../../lib/util/util.js"

const error = ref(null)
const loading = ref(false)

const createPodcast = async (podcast, applinks) => {
	loading.value = true
	await sleep(1000)
	try {
		const podcastID = await PodcastService.Create(podcast)
		applinks.forEach((_, idx) => {
			applinks[idx].podcastID = podcastID
		})
		await PodcastService.SetAppLinks(applinks)
		error.value = null
		loading.value = false
		return podcastID
	} catch (err) {
		error.value = err.message
		loading.value = false
	}
}

const useCreatePodcast = () => {
	return { error, loading, createPodcast }
}

export default useCreatePodcast