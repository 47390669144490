import { ref } from "vue"
import { sleep } from "../../lib/util/util.js"
import PlayerService from "../../services/PlayerService"

const error = ref(null)
const loading = ref(false)

const updatePlayer = async (player) => {
	loading.value = true
	await sleep(1000)
	try {
		await PlayerService.Update(player)
		error.value = null
		loading.value = false
	} catch (err) {
		error.value = err.message
		loading.value = false
	}
}

const useUpdatePlayer = () => {
	return { error, loading, updatePlayer }
}

export default useUpdatePlayer