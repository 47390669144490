import { reactive, ref } from "vue"

function useModal() {
	let show = ref(false)
	const close = () => show.value = false
	const open = () => show.value = true

	return reactive({ show, close, open })
}

export default useModal