<template>
	<div class="mt-5">
		<label for="email" class="block font-medium text-sm text-gray-700">{{ label }}</label>
		<div class="mt-2">
			<input type="text" :value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
			class="shadow-sm placeholder-gray-300 focus:ring-green-600 focus:border-green-600 block w-full border-gray-300 rounded-md" :placeholder="placeholder"/>
		</div>		
		<p v-if="$slots.subtext" class="mt-2 text-sm text-gray-500" id="email-description">
			<slot name="subtext"></slot>
		</p>
	</div>
</template>

<script>
	export default {
		props: ["modelValue", "label", "placeholder"],
	};
</script>