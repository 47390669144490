<template>
	<h1 class="text-5xl font-bold font-heading mb-12 text-gray-900"><slot></slot></h1>
</template>

<script>
export default {

}
</script>

<style>

</style>