class Player {
	constructor({ id, name, settings, type, podcastID }) {
		this.id = id
		this.name = name
		this.settings = settings
		this.type = type
		this.podcastID = podcastID
	}

	get shortName() {
		if (this.name.length < 35) return this.name
		return this.name.slice(0, 35) + " ..."
	}

	get typeToString() {
		if (this.type === 1) return "Single"
		if (this.type === 2) return "Latest"
		if (this.type === 3) return "Archive"
		return ""
	}

	isSingle() {
		return this.type === 1
	}
	isLatest() {
		return this.type === 2
	}
	isArchive() {
		return this.type === 3
	}

	belongsToPodcast(podcastID) {
		return podcastID === this.podcastID
	}
}

export default Player