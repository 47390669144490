<template>
	<Navigation />
	<Banners />
	<div class="ml-28 px-20 py-12">
		<PageHeading>Help</PageHeading>
		<div class="pb-5 mb-8">
		<h3 class="text-2xl mb-5 leading-6 font-bold text-gray-900">
			Welcome to the Help Section!
		</h3>
		<p class="mt-2 max-w-4xl text-gray-500">On this page you will find useful information, tutorials and Frequently Asked Questions (FAQ) for Podplayer.co!</p>
		<p class="mt-2 max-w-4xl text-gray-500">If you run into problems while creating your players, you will find a solution on this page!</p>

		<FAQ />
		<TutorialVideos />

	</div>
	</div>
</template>

<script>
import Navigation from "../components/Navigation.vue"
import Banners from "../components/Banners.vue"
import PageHeading from "../components/headings/PageHeading.vue"
import FAQ from "../components/FAQ.vue"
import TutorialVideos from "../components/TutorialVideos.vue"

export default {
	components: { Navigation, Banners, PageHeading, FAQ, TutorialVideos },
	setup() {
		return {	}
	},
}
</script>