<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div v-if="!user.emailVerified"
	class="p-2 pb-0 ml-28">
		<div class=" bg-green-600 rounded-md shadow-lg">
		<div class="max-w-7xl mx-auto py-6 px-3 sm:px-6 lg:px-8">
			<div class="flex items-center justify-between flex-wrap">
			<div class="w-0 flex-1 flex items-center">
				<span class="flex p-2 rounded-lg bg-green-700">
					<MailIcon class="h-6 w-6 text-white" aria-hidden="true" />
				</span>
				<p class="ml-3 font-medium text-white truncate">
					<span class="md:hidden">Activate Email Address!</span>
					<span class="hidden md:inline">
						<span class="font-bold">Important!</span> You must activate your email address!
					</span>
				</p>
			</div>
			<div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
				<a @click="sendEmailVerification" v-show="!verificationEmailResent"
				class="flex items-center justify-center cursor-pointer px-4 py-2 border border-transparent
				rounded-md shadow-sm text-base font-medium text-green-600 bg-white hover:bg-green-50
				">
					Resend confirmation email					
				</a>
				<p v-show="verificationEmailResent" class="text-base text-white font-medium">
					Check your inbox! <i>(And spam folder)</i>
				</p>
			</div>
			
			</div>
		</div>
	</div>
	</div>
</template>

<script>
import { ref } from "vue"
import { MailIcon } from '@heroicons/vue/solid'
import getUser from "../../hooks/getUser.js"

export default {
	components: { MailIcon },
	setup() {
		const { user } = getUser() 

		const verificationEmailResent = ref(false)

		const sendEmailVerification = () => {
			user.value.firebaseUser.sendEmailVerification()
			verificationEmailResent.value = true	
		}
		
		return { user, sendEmailVerification, verificationEmailResent }
	}
}
</script>