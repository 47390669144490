<template>
	<Navigation />
	<Banners />
	<div class="ml-28 px-20 py-12">
		<PageHeading>Settings</PageHeading>		
		<div class="pb-5 border-b border-gray-200">
			<h3 class="text-xl leading-6 font-medium text-gray-900">
				Job Postings
			</h3>
			<p class="mt-2 max-w-4xl text-gray-500">Workcation is a property rental website. Etiam ullamcorper massa viverra consequat, consectetur id nulla tempus. Fringilla egestas justo massa purus sagittis malesuada.</p>
		</div>

	</div>
</template>

<script>
import Navigation from "../components/Navigation.vue"
import Banners from "../components/Banners.vue"
import PageHeading from "../components/headings/PageHeading.vue"

export default {
	components: { Navigation, Banners, PageHeading },
	setup() {
		return {	}
	},
}
</script>