import client from "@/lib/axios/config.js"
import Podcast from "../classes/Podcast"

async function Create(podcast /*Podcast*/) {
	try {
		const res = await client.post("/client/podcasts", podcast)
		const podcastID = res.data
		return podcastID
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function Update(podcast /*Podcast*/) {
	try {
		await client.put(`/client/podcasts/${podcast.id}`, podcast)
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function Delete(podcastID) {
	try {
		await client.delete(`/client/podcasts/${podcastID}`)
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function ByUser() {
	const res = await client.get("/client/podcasts")
	const podcasts = []
	res.data.forEach(pData => {
		podcasts.push(new Podcast({
			name: pData.name,
			rss: pData.rss,
			userID: pData.userID,
			podcastID: pData.podcastID,
			artworkURL: pData.artworkURL,
			domain: pData.domain,
		}))
	})
	return podcasts
}

async function SetAppLinks(applinks) {
	try {
		await client.post("/client/applinks", applinks)
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function AppLinksByPodcastID(podcastID) {
	try {
		const res = await client.get(`/client/applinks/${podcastID}`)
		const applinks = res.data 
		return applinks
	} catch (err) {
		throw new Error(err.response.data)
	}
}

export default { Create, Update, Delete, ByUser, SetAppLinks, AppLinksByPodcastID }