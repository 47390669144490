<template>
<BaseModal @close="closeModal" :show="show" :twClasses="'w-11/12 lg:w-240'">
	<h2 class="text-gray-900 text-3xl font-bold mb-2">
		<span v-if="playertype == 1">Create a new Single-Player!</span>
		<span v-if="playertype == 2">Create a new Latest-Player!</span>		
	</h2>

	<div class="flex space-x-4">
	<!-- PLAYER NAME FIELD -->	
	<TextInput class="flex-grow" v-model="form.playerName" :label="'Your player\'s name'" :placeholder="'My amazing player'">
		<template v-slot:subtext>➤ <router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">Watch video tutorial for Single players!</router-link></template>
	</TextInput>

	<!-- PLAYER PODCAST FIELD -->
	<div class="mt-5 flex-grow">
		<label class="block font-medium text-sm text-gray-700">Podcast the player belongs to</label>
		<div class="mt-2">
			<select v-model="form.podcastID"
			class="shadow-sm cursor-pointer focus:ring-green-600 focus:border-green-600 block w-full border-gray-300 rounded-md" name="sticky">
				<option v-for="p in podcasts" :value="p.id" :key="p.id">{{ p.shortName }}</option>
			</select>			
		</div>
	</div>

	</div>
	<!-- <h2 class="text-gray-900 text-2xl font-bold my-8">
		Player preview
	</h2> -->

	<!-- SETTINGS -->
	<h2 class="text-gray-900 text-2xl font-bold mt-8">
		Settings
	</h2>
	<div class="flex space-x-4">
		<TextInput class="flex-grow" v-model="form.waveColor" :label="'Audio Wave Color'" :placeholder="'#66CCFF'">
			<template v-slot:subtext>➤ <router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">How to set the audio wave color?</router-link></template>
		</TextInput>

		<div class="mt-5 flex-grow">
			<label class="block font-medium text-sm text-gray-700">Sticky Player?</label>
			<div class="mt-2">
				<select v-model="form.sticky"
				class="shadow-sm cursor-pointer focus:ring-green-600 focus:border-green-600 block w-full border-gray-300 rounded-md" name="sticky">
					<option :value="0" selected>Only normal player</option>
					<option :value="1">Only Sticky player</option>
					<option :value="2">Normal and Sticky player</option>
				</select>
			</div>
			<p class="mt-2 text-sm text-gray-500" id="email-description">➤ <router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">What is a sticky player?</router-link></p>
		</div>		
	</div>

	<div class="mt-5 mb-4 flex-grow" @click="handleRemoveBrandingClick">
		<label class="block font-medium text-sm text-gray-700">
			<b v-if="user.isFreeUser()" 
			class="font-bold cursor-pointer">🔒 [PRO]</b>
			Podplayer.co branding?</label>
		<div class="mt-2">
			<select v-model="form.removeBranding" :disabled="user.isFreeUser()"
			class="shadow-sm cursor-pointer focus:ring-green-600 focus:border-green-600 block w-full border-gray-300 rounded-md" name="sticky">
				<option :value="false">Show 'Powered by Podplayer.co' branding!</option>
				<option :value="true">No, remove it!</option>
			</select>
		</div>
		<!-- <p class="mt-2 text-sm text-gray-500" id="email-description">What is a branding?</p> -->
	</div>
	


	<div class="inline-flex justify-center items-center h-12 mt-8 mb-2">
		<button v-if="!loading" @click="handleSubmit"
		class="p-5 rounded bg-green-600 text-white hover:bg-green-700">
			Create Player!
		</button>
		<Spinner v-if="loading"/>
	</div>
	<ErrorCard v-if="!loading && error">
		{{ error }}
	</ErrorCard>

	
	
</BaseModal>
</template>

<script>
import { reactive } from "vue"
import { useRouter } from "vue-router"
import useCreatePlayer from "../hooks/player/useCreatePlayer"
import useGetPodcasts from "../hooks/podcast/useGetPodcasts"
import BaseModal from "./base/BaseModal.vue"
import Spinner from "../components/Spinner.vue"
import Player from '../classes/Player'
import ErrorCard from "../components/ErrorCard.vue"
import TextInput from "../components/TextInput.vue"
import getUser from '../hooks/getUser'

export default {
	components: { BaseModal, Spinner, ErrorCard, TextInput },
	props: ["show", "playertype"],
	setup(props, { emit }) {
		const router = useRouter()
		const { user } = getUser()
		const { error, loading, createPlayer } = useCreatePlayer()
		const { podcasts, getPodcasts } = useGetPodcasts()
		getPodcasts()
		const form = reactive({
			podcastID: "", 
			playerName: "",
			waveColor: "",
			sticky: 0,
			removeBranding: false,
		})
		const closeModal = () => {
			emit("close")
			error.value = ""
		}	

		const handleSubmit = async () => {
			console.log({
				colors: { wave: form.waveColor },
				sticky: parseInt(form.sticky),
				removeBranding: form.removeBranding,
			})
			if (form.podcastID.length < 5) {
				error.value = "Please select a Podcast"
				return
			}
			if ((form.playerName.length < 5)) {
				error.value = "Invalid Player name"
				return
			}
			await createPlayer(new Player({
				name: form.playerName, 
				podcastID: form.podcastID,
				type: props.playertype,
				settings: {
					colors: { wave: form.waveColor },
					sticky: parseInt(form.sticky),
					removeBranding: form.removeBranding,
				}
			}))
			if (!error.value) router.go(0)
		}

		const handleRemoveBrandingClick = () => {
			if (!user.value.isFreeUser()) return
			router.push('/upgrade-plan?title=Free Users can not remove the Podplayer.co branding!')
		}
		
		return { user, closeModal, handleSubmit, form, loading, error, podcasts, handleRemoveBrandingClick }
	}

}
</script>

<style>

</style>