import client from "@/lib/axios/config.js"

async function CreateCheckoutSession(planID) {
	try {
		const res1 = await client.get(`/client/plans/${planID}`)
		const { stripePriceID } = res1.data
		const checkoutLink = await CreateCheckoutSessionByStripePriceID(stripePriceID)
		return checkoutLink
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function CreateCheckoutSessionByStripePriceID(stripePriceID) {
	try {
		const res = await client.post("/client/stripe/checkoutsession/new", { stripePriceID })
		return res.data.checkoutLink
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function CreatePortalSession() {
	try {
		const res = await client.post("/client/stripe/portalsession/new")
		return res.data.portalLink
	} catch (err) {
		throw new Error(err.response.data)
	}
}

export default { CreateCheckoutSession, CreatePortalSession }