<template>
	<div v-if="show" @click.self="closeModal" class="fixed top-0 bottom-0 left-0 right-0 backdrop-filter backdrop-blur backdrop-brightness-75 w-full h-full flex items-center justify-center">
		<div class="rounded-lg bg-white z-50 shadow relative p-12 overflow-x-hidden overflow-y-auto" style="max-height: 85vh;"
		:class="twClasses">
			<div @click="closeModal"
			class="font-bold text-3xl absolute top-2 right-4 text-gray-500 hover:text-gray-900 cursor-pointer">x</div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: ["show", "twClasses"],
	setup(_, { emit }) {
		function closeModal() {
			emit("close")
		}
		return { closeModal }
	}
}
</script>

<style>
	
</style>