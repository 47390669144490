<template>
<BaseModal @close="closeModal" :show="show" :twClasses="'w-10/12 lg:w-240'">
	<h2 class="text-gray-900 text-3xl font-bold mb-5">
		{{ mode == 'edit' ? 'Edit' : 'Embed' }} {{ player.name }}
	</h2>		
	<div @click="setMode('edit')"
	class="inline-flex justify-center py-3 w-24 bg-gray-100 mr-2 cursor-pointer hover:bg-gray-300">
		Edit
	</div>
	<div @click="setMode('embed')"
	class="inline-flex justify-center py-3 w-24 bg-gray-100 cursor-pointer hover:bg-gray-300">
		Embed
	</div>

	<!-- EDIT MODE -->
	<div v-if="mode == 'edit'">
		<div class="flex space-x-4">
		<!-- PLAYER NAME FIELD -->
		<TextInput class="flex-grow" v-model="form.playerName" :label="'Your player\'s name'" :placeholder="'My amazing player'">
			<template v-slot:subtext><router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">➤ Watch video tutorial for Single players!</router-link>	</template>
		</TextInput>

		</div>
		<!-- <h2 class="text-gray-900 text-2xl font-bold my-8">
			Player preview
		</h2>
		<div ref="previewPlayer"></div> -->

		<!-- SETTINGS -->
		<h2 class="text-gray-900 text-2xl font-bold mt-8">
			Settings
		</h2>
		<div class="flex space-x-4">
			<TextInput class="flex-grow" v-model="form.waveColor" :label="'Audio Wave Color'" :placeholder="'#66CCFF'">
				<template v-slot:subtext>➤ <router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">How to set the audio wave color?</router-link></template>
			</TextInput>

			<div class="mt-4 flex-grow">
				<label class="block font-medium text-sm text-gray-700">Sticky Player?</label>
				<div class="mt-2">
					<select v-model="form.sticky"
					class="shadow-sm cursor-pointer focus:ring-green-600 focus:border-green-600 block w-full border-gray-300 rounded-md" name="sticky">
						<option value="0" selected>Only normal player</option>
						<option value="1">Only Sticky player</option>
						<option value="2">Normal and Sticky player</option>
					</select>
				</div>
				<p class="mt-2 text-sm text-gray-500" id="email-description">➤ <router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">What is a sticky player?</router-link></p>
			</div>			
		</div>

		<div class="mt-5 flex-grow" @click="handleRemoveBrandingClick">
			<label class="block font-medium text-sm text-gray-700">
				<span v-if="user.isFreeUser()" 
				class="font-bold cursor-pointer">🔒 [PRO]</span>
				Podplayer.co branding?</label>
			<div class="mt-2">
				<select v-model="form.removeBranding" :disabled="user.isFreeUser()"
				class="shadow-sm cursor-pointer focus:ring-green-600 focus:border-green-600 block w-full border-gray-300 rounded-md" name="sticky">
					<option :value="false">Show 'Powered by Podplayer.co' branding!</option>
					<option :value="true">No, remove the branding!</option>
				</select>
			</div>
		</div>

		<div class="flex justify-between items-center h-12 mt-8 mb-2">
			<button v-if="!loading" @click="handleUpdatePlayer"
			class="p-5 rounded bg-green-600 text-white hover:bg-green-700">
				Update Player!
			</button>
			<Spinner v-if="loading"/>

			<button v-if="!deleteLoading" @click="handleDeletePlayer"
			class="p-5 rounded bg-red-600 text-white hover:bg-red-700">
				Delete Player!
			</button>
			<Spinner v-if="deleteLoading"/>
		</div>
		<ErrorCard v-if="!loading && error">
			{{ error }}
		</ErrorCard>
		<ErrorCard v-if="!deleteLoading && deleteError">
			{{ error }}
		</ErrorCard>
	</div>
	<!-- EMBED MODE -->
	<div v-if="mode == 'embed' && player.isLatest()">	
		<p class="mt-6"><b>Important:</b> this player is a so called "Latest Player". This means it will always show your latest and most recent episode! Whenever you upload a new episode the episode displayed by this player will change!</p>
		<div class="mt-8 flex-grow">
			<label class="block font-medium text-sm text-gray-700">Copy and paste this code snippet to your website</label>
			<div class="mt-2 flex space-x-1">
				<input type="text" readonly
				class="shadow-sm block w-full border-gray-300 rounded-md focus:ring-0 focus:border-gray-300"
				:value="embedScriptLatest"/>
				<button @click="copyTextToClipboard(embedScriptLatest)"
				class="rounded-md p-3 bg-gray-700 text-gray-50
				hover:bg-gray-800">Copy</button>
			</div>
			<p class="mt-2 text-sm text-gray-500" id="email-description">Watch video: <router-link to="/help#tutorial-videos" class="underline hover:text-gray-800">How to embed player code snippet on website?</router-link></p>
		</div>
	</div>
	<div v-if="mode == 'embed' && player.isSingle()">
		<h2 class="text-gray-900 text-2xl font-bold mt-8 mb-5">
			Search episode to embed
		</h2>
		<div class="mb-4 flex space-x-1 col-span-full relative">				
			<input type="text" v-model="titleSearchString"
			class="shadow-sm w-full p-4 border-gray-300 rounded-md focus:ring-0 focus:border-gray-300" placeholder="Enter a part of an episode's title"/>
			<button @click="searchEpisodesByTitle(player.podcastID)"
			class="rounded-md p-3 w-48 bg-green-600 text-gray-50 hover:bg-green-700">
				Search episode
			</button>
		</div>
		<div v-if="!loadingSearchEpisodes && !errorSearchEpisodes" class="space-y-3">
			<EpisodeEmbedCard v-for="ep in searchedEpisodes" :episode="ep" :key="ep.guid" :player="player" />
		</div>
		<Spinner v-if="loadingSearchEpisodes && !errorSearchEpisodes"/>

		<h2 class="text-gray-900 text-2xl font-bold mt-8 mb-5">
			Your last 5 episodes
		</h2>
		<div v-if="!loadingLatestEpisodes && !errorLatestEpisodes" class="space-y-3">
			<EpisodeEmbedCard v-for="ep in latestEpisodes" :episode="ep" :key="ep.guid" :player="player" />
		</div>
		<Spinner v-if="loadingLatestEpisodes && !errorLatestEpisodes"/>		
	</div>	
</BaseModal>
</template>

<script>
import { ref, reactive, computed, watch } from "vue"
import { useRouter } from "vue-router"
import useUpdatePlayer from "../hooks/player/useUpdatePlayer"
import useDeletePlayer from "../hooks/player/useDeletePlayer"
import useGetPodcasts from "../hooks/podcast/useGetPodcasts"
import useGetLatestEpisodes from "../hooks/episodes/useGetLatestEpisodes"
import useSearchEpisodesByTitle from "../hooks/episodes/useSearchEpisodesByTitle"
import BaseModal from "./base/BaseModal.vue"
import Spinner from "../components/Spinner.vue"
import Player from '../classes/Player'
import ErrorCard from "../components/ErrorCard.vue"
import TextInput from "../components/TextInput.vue"
import EpisodeEmbedCard from "./EpisodeEmbedCard.vue"
import { copyTextToClipboard } from "../lib/util/util.js"
import getUser from '../hooks/getUser'
import config from "../config"

export default {
	components: { BaseModal, Spinner, ErrorCard, EpisodeEmbedCard, TextInput },
	props: ["show", "player"],
	setup(props, { emit }) {
		const router = useRouter()		
		const { user } = getUser()
		const { error, loading, updatePlayer } = useUpdatePlayer()
		const { error: deleteError, loading: deleteLoading, deletePlayer } = useDeletePlayer()
		const { podcasts, getPodcasts } = useGetPodcasts()
		getPodcasts()
		const form = reactive({
			playerName: "",
			waveColor: "",
			sticky: 0,
			removeBranding: false,
		})

		const closeModal = () => {
			emit("close")
			error.value = ""
			mode.value = "edit"
		}	

		const handleUpdatePlayer = async () => {
			if (form.playerName.length < 5) {
				error.value = "Invalid Player name"
				return
			}
			await updatePlayer(new Player({
				id: props.player.id,
				name: form.playerName, 
				settings: {
					colors: { wave: form.waveColor },
					sticky: parseInt(form.sticky),
					removeBranding: form.removeBranding,
				}
			}))
			if (!error.value) router.go(0)
		}
		const handleDeletePlayer = async () => {
			await deletePlayer(props.player.id)
			if (!deleteError.value) router.go(0)
		}

		const mode = ref("edit")
		const setMode = m => mode.value = m
		const embedScriptLatest = computed(() => `\<script async src='${config.SERVER_BASE_URL}/static/player/player.js' data-podcastplayerco_type='latest' data-podcastplayerco_key='${props.player?.id}.000000.${props.player?.podcastID}'>\<\/script\>`)

		const { 
			loading: loadingSearchEpisodes, 
			error: errorSearchEpisodes, 
			episodes: searchedEpisodes,
			searchEpisodesByTitle,
			searchString: titleSearchString,
			clearSearchedEpisodes,
		} = useSearchEpisodesByTitle()
		
		const { 
			loading: loadingLatestEpisodes, 
			error: errorLatestEpisodes, 
			episodes: latestEpisodes,
			getLatestEpisodes,
		} = useGetLatestEpisodes()		

		// const previewPlayer = ref(null)		

		// This watcher executes necesary actions when the player to edit changes!
		watch(
			() => props.player,
			(newPlayer, oldPlayer) => {			
				form.playerName = newPlayer.name ?? ""
				form.waveColor = newPlayer.settings.colors.wave ?? ""
				form.sticky = newPlayer.settings.sticky ?? 0
				form.removeBranding = newPlayer.settings.removeBranding ?? false
				if (newPlayer.isSingle()) {
					getLatestEpisodes(5, newPlayer.podcastID)
					clearSearchedEpisodes()
				}
				// setTimeout(() => {
				// 	const previewScript = document.createElement("script")
				// 	previewScript.setAttribute("src", `${config.SERVER_BASE_URL}/static/player/player.js`)
				// 	previewScript.setAttribute("data-podcastplayerco_type", "latest")
				// 	previewScript.setAttribute("data-podcastplayerco_key", `${props.player?.id}.000000.${props.player?.podcastID}`)
				// 	previewPlayer.value.appendChild(previewScript)
				// })
			}
		)

		const handleRemoveBrandingClick = () => {
			if (!user.value.isFreeUser()) return
			router.push('/upgrade-plan?title=Free Users can not remove the Podplayer.co branding!')
		}
		
		return { 
			user, closeModal, handleUpdatePlayer, handleDeletePlayer, form, loading, deleteLoading, error, deleteError, podcasts, mode, setMode,
			embedScriptLatest, copyTextToClipboard, latestEpisodes,
			loadingLatestEpisodes, errorLatestEpisodes,
			loadingSearchEpisodes, errorSearchEpisodes, searchedEpisodes, searchEpisodesByTitle, titleSearchString,
			handleRemoveBrandingClick,
			// previewPlayer,
		}
	}

}
</script>

<style>

</style>