<template>
	<Navigation />
	<Banners />
	<AddPlayerModal :show="addPlayerModal.show" @close="addPlayerModal.close" :playertype="newPlayertype"/>
	<EditPlayerModal :show="editPlayerModal.show" @close="editPlayerModal.close" :player="playerToEdit"/>
	<div class="ml-28 px-20 py-12">
		<div class="flex space-x-5">
			<PageHeading>Players <span v-if="user.isPaidUser()" class="font-light text-gray-700">({{ user.roleString }})</span></PageHeading>			
			<p class="mt-4 text-md">show for:</p>
			<div class="w-72 mt-2">
				<BaseDropdown :items="dropdownPodcasts" 
				:itemValueCallback="podcast => podcast.shortName" 
				:itemKeyCallback="podcast => podcast.id"
				@change="handleDropdownChange"/>
			</div>
		</div>

		<!-- SINGLE PLAYER SECTION -->
		<section class="mb-12">
			<div class="pb-5 mb-8 border-b border-gray-200">
				<h3 class="text-2xl leading-8 font-bold text-gray-900">					
					Single Players
				</h3>
				<p class="my-2 max-w-4xl text-gray-500">Single Players are the players that you can use to put on the episode specific subpages of your website, so that people visiting your website can listen to your episodes with this player!</p>
				<router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">➤ Watch video tutorial for Single players!</router-link>				
			</div>
			<div @click="newPlayertype = 1; handleAddModalOpen()" v-if="!loadingPlayers"
			class="w-60 h-16 my-10 text-white space-x-2 rounded-md ml-1 bg-green-600 flex items-center justify-center cursor-pointer hover:bg-green-700">
				<p class="text-3xl font-bold pb-1">+</p>
				<p class="text-lg">Create Single player</p>
			</div>
			<div class="grid grid-cols-2 gap-4" v-if="!loadingPlayers">
				<PlayerCard v-for="p in singlePlayers" :key="p.id" :player="p" 
				@click="editPlayerModal.open(); setPlayerToEdit(p)"/>
			</div>
			<Spinner v-if="loadingPlayers"/>
		</section>
		
		<!-- LATEST PLAYER SECTION -->
		<section class="mb-12">
			<div class="pb-5 mb-8 border-b border-gray-200">
				<h3 class="text-2xl leading-8 font-bold text-gray-900">
					<span v-if="user.isFreeUser()" class="font-bold cursor-pointer">🔒</span>
					Latest Players
				</h3>
				<p class="my-2 max-w-4xl text-gray-500">Latest players are like single players, except that they always play the latest episode of your Podcast. Very useful for your website's frontpage!</p>
				<router-link to="/help#tutorial-videos" class="underline text-gray-500 hover:text-gray-800">➤ Watch video tutorial for Latest players!</router-link>				
			</div>
			<div @click="newPlayertype = 2; handleAddModalOpen()" v-if="!loadingPlayers"
			class="w-60 h-16 my-12 text-white space-x-2 rounded-md ml-1 bg-green-600 flex items-center justify-center cursor-pointer hover:bg-green-700">
				<p class="text-3xl font-bold pb-1">+</p>
				<p class="text-lg">Create Latest player</p>
			</div>
			<div class="grid grid-cols-2 gap-4" v-if="!loadingPlayers">
				<PlayerCard v-for="p in latestPlayers" :key="p.id" :player="p" 
				@click="editPlayerModal.open(); setPlayerToEdit(p)"/>
			</div>
			<Spinner v-if="loadingPlayers"/>
		</section>

		<div class="pb-5 mb-8 border-b border-gray-200 cursor-not-allowed">
			<h3 class="text-2xl leading-6 font-bold text-gray-400">
				<span v-if="user.isFreeUser()" class="font-bold cursor-pointer">🔒</span>
				Archive players (coming soon)
			</h3>
			<p class="mt-2 max-w-4xl text-gray-400">Archive players, show a list of all your episodes, and let the user choose which episode to play, or play them one after the other!</p>
		</div>
	</div>
</template>

<script>
import { computed, ref } from "vue"
import { useRouter } from "vue-router"
import useGetPodcasts from "../hooks/podcast/useGetPodcasts"
import useGetPlayersByUser from "../hooks/player/useGetPlayersByUser"
import getUser from '../hooks/getUser'
import useModal from "../hooks/useModal"
import Navigation from "../components/Navigation.vue"
import BaseDropdown from "../components/base/BaseDropdown.vue"
import PlayerCard from "../components/PlayerCard.vue"
import AddPlayerModal from "../components/AddPlayerModal.vue"
import EditPlayerModal from "../components/EditPlayerModal.vue"
import Spinner from "../components/Spinner.vue"
import Banners from "../components/Banners.vue"
import PageHeading from "../components/headings/PageHeading.vue"

export default {
	components: { Navigation, BaseDropdown, PlayerCard, AddPlayerModal, EditPlayerModal, Spinner, Banners, PageHeading },
	setup() {
		const router = useRouter()
		const { user } = getUser()
		const { podcasts, getPodcasts } = useGetPodcasts()
		const { players, getPlayers, loading: loadingPlayers } = useGetPlayersByUser()
		getPodcasts()	
		getPlayers()	

		const dropdownPodcasts = computed(() => [
			{ id: 0, name: "All Podcasts", shortName: "All Podcasts" },
			...podcasts.value
		])
		const selectedPodcast = ref(dropdownPodcasts.value[0])

		function handleDropdownChange(selectedIdx) {
			selectedPodcast.value = dropdownPodcasts.value[selectedIdx]
		}
		
		const playersFilteredByPodcast = computed(() => {
			let filteredPlayers = players.value.filter(player => {
				if (selectedPodcast.value?.id === 0) return true
				return player.belongsToPodcast(selectedPodcast.value?.id)
			})
			filteredPlayers = filteredPlayers.map(player => {
				player.artworkURL = findPodcastByID(dropdownPodcasts, player.podcastID).artworkURL
				return player
			})			
			return filteredPlayers
		})		
		const singlePlayers = computed(() => playersFilteredByPodcast.value.filter(player => player.isSingle()))
		const latestPlayers = computed(() => playersFilteredByPodcast.value.filter(player => player.isLatest()))

		const newPlayertype = ref(1)

		const addPlayerModal = useModal()
		const editPlayerModal = useModal()
		const handleAddModalOpen = () => {
			if (user.value.isFreeUser() && newPlayertype.value == 2) {				
				const upgradePageTitle = "Free users can't create Latest-Players! But don't worry:"
				router.push(`/upgrade-plan?title=${upgradePageTitle}`)
				return
			}
			if (players.value.length >= user.value.maxPlayers) {
				if (user.value.isProUser()) return // (weil im Moment noch kein höherer Plan verfügbar ist)
				const upgradePageTitle = "Free users can only create 2 players! But don't worry:"
				router.push(`/upgrade-plan?title=${upgradePageTitle}`)
				return
			}
			addPlayerModal.open()
		}

		const playerToEdit = ref(null)
		const setPlayerToEdit = p => playerToEdit.value = p

		return { 
			dropdownPodcasts, handleDropdownChange, singlePlayers, latestPlayers, addPlayerModal, handleAddModalOpen, editPlayerModal,
			newPlayertype, playerToEdit, setPlayerToEdit, loadingPlayers, user, players,
		}
	},
}

function findPodcastByID(podcasts, podcastID) {
	return podcasts.value.find(podcast => podcast.id == podcastID)
}
</script>