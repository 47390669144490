<template>	
		<div id="tutorial-videos" class="max-w-7xl py-12 divide-y divide-gray-200 lg:py-16">
			<h2 class="text-2xl font-bold text-gray-900">
				Podplayer Tutorial Videos
			</h2>
			<div class="mt-8 pt-8 grid grid-cols-2 gap-5">
				<div v-for="v in videos" :key="v.title+v.url" class="col-span-1">
					<h3 class="text-xl py-3">{{v.title}}</h3>
					<iframe :src="v.url" width="100%" style="aspect-ratio:16/9;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>					
				</div>
			</div>
		</div>
	
</template>

<script>
const videos = [
	{
		title: "Getting started with Podplayer",
		url: "https://www.youtube-nocookie.com/embed/KgiE9wh4haU",
	},{
		title: "RSS Feed & Link explained",
		url: "https://www.youtube-nocookie.com/embed/MByA1RQWiIc",
	},{
		title: "Change Color of Audio Wave",
		url: "https://www.youtube-nocookie.com/embed/CdTUWw2dQTM",
	},{
		title: "How to add Applinks? (Spotify, etc.)",
		url: "https://www.youtube-nocookie.com/embed/EiwaOlV3xtc",
	},{
		title: "Embed Player on Website",
		url: "https://www.youtube-nocookie.com/embed/wAkm3-r2eMA",
	},{
		title: "Single vs. Latest Player",
		url: "https://www.youtube-nocookie.com/embed/4VHBWU-AFmc",
	},{
		title: "Sticky vs. Normal Player",
		url: "https://www.youtube-nocookie.com/embed/j-5FA-IfaHQ",
	},

	// More videos...
]

export default {
  setup() {
    return {
      videos,
    }
  },
}
</script>
