class User {
	constructor({ firebaseUser, currentPeriodEnd, userRoleID }) {
		this.firebaseUser = firebaseUser
		this.currentPeriodEnd = currentPeriodEnd
		this.role = userRoleID
	}

	get roleString() {
		return {"1": "Free", "2": "Pro", "3": "Business"}[this.role]
	}

	get emailVerified() {
		return this.firebaseUser.emailVerified
	}

	isFreeUser() {
		return this.role === 1
	}
	isProUser() {
		return this.role === 2
	}
	isBusinessUser() {
		return this.role === 3
	}
	isPaidUser() {
		return this.isProUser() || this.isBusinessUser()
	}

	forceTokenRefresh() {
		this.firebaseUser.getIdToken(true)
	}

	// --- RULES ---
	get maxPlayers() {
		if (this.isFreeUser()) return 2
		if (this.isProUser()) return 100		
	}
	get maxPodcasts() {
		if (this.isFreeUser()) return 1
		if (this.isProUser()) return 3	
	}
	get canRemoveBranding() {
		return this.isProUser()
	}
	get canUseArchivePlayer() {
		return this.isProUser()
	}
}

export default User
