<template>
<BaseModal @close="closeModal" :show="show" :twClasses="'w-11/12 md:w-200'">
	<h2 class="text-gray-900 text-3xl font-bold">
		Edit Podcast information
	</h2>
	<p class="text-gray-600 mt-1">{{ podcast?.name }}</p>

	<!-- RSS FIELD -->
	<TextInput v-model="form.podcastRSS"
	:label="'Link to your RSS Feed'" :placeholder="'https://feeds.yourpodcasthoster.com/abcdef123.rss'">
		<template v-slot:subtext>Watch video: <a href="#" class="underline hover:text-gray-800">Where to find my Podcasts RSS-Link?</a></template>
	</TextInput>

	<!-- DOMAIN FIELD -->
	<TextInput v-model="form.podcastDomain"
	:label="'Domain'" :placeholder="'www.yourwebsite.com'">
		<template v-slot:subtext>Enter your website's domain, where you will use the Podcast Player!</template>
	</TextInput>


	<h3 class="text-2xl font-bold mt-5">Applinks</h3>
	<p class="mt-2 text-sm text-gray-500" id="email-description">Watch video: <a href="#" class="underline hover:text-gray-800">What are Applinks and where to find them?</a></p>

	<div class="grid grid-cols-2 gap-x-3">
		<TextInput v-model="form.spotifyURL" :label="'Spotify'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.appleURL" :label="'Apple Podcasts'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.googleURL" :label="'Google Podcasts'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.castboxURL" :label="'Castbox'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.podchaserURL" :label="'Podchaser'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.tuneinURL" :label="'TuneIn + Alexa'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.overcastURL" :label="'Overcast'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.podcastaddictURL" :label="'Podcast Addict'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.stitcherURL" :label="'Stitcher'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
	</div>
	

	<div class="flex mt-8 justify-between">
		<div class="w-40 flex items-center justify-center">
			<button v-if="!loading" @click="handleSubmit"
			class="p-5 rounded bg-green-600 text-white hover:bg-green-700 flex-grow">
				Save changes!
			</button>
			<Spinner v-if="loading"/>
		</div>
		<div class="w-40 flex items-center justify-center">
			<button v-if="!deleteLoading" @click="handleDelete"
			class="p-5 rounded bg-red-500 text-white hover:bg-red-700 flex-grow">
				Delete Podcast!
			</button>
			<Spinner v-if="deleteLoading"/>		
		</div>
	</div>
		
	<ErrorCard v-if="!loading && error">
		{{ error }}
	</ErrorCard>
	<ErrorCard v-if="!deleteLoading && deleteError">
		{{ deleteError }}
	</ErrorCard>

	
	
</BaseModal>
</template>

<script>
import { reactive, watchEffect } from "vue"
import { useRouter } from "vue-router"
import useUpdatePodcast from "../hooks/podcast/useUpdatePodcast"
import useDeletePodcast from "../hooks/podcast/useDeletePodcast"
import BaseModal from "./base/BaseModal.vue"
import Spinner from "../components/Spinner.vue"
import Podcast from '../classes/Podcast'
import ErrorCard from "../components/ErrorCard.vue"
import PodcastService from '../services/PodcastService'
import TextInput from "../components/TextInput.vue"

export default {
	components: { BaseModal, Spinner, ErrorCard, TextInput },
	props: ["show", "podcast"],
	setup(props, { emit }) {
		const router = useRouter()
		const { error, loading, updatePodcast } = useUpdatePodcast()
		const { error: deleteError, loading: deleteLoading, deletePodcast } = useDeletePodcast()
		const form = reactive({
			podcastDomain: "", 
			podcastRSS: "",
			spotifyURL: "",
			appleURL: "",
			googleURL: "",
			castboxURL: "",
			podchaserURL: "",
			tuneinURL: "",
			overcastURL: "",
			podcastaddictURL: "",
			stitcherURL: "",
		})		

		watchEffect(async () => {
			if (!props.podcast) return
			form.podcastDomain = props.podcast.domain
			form.podcastRSS = props.podcast.rss
			const applinks = await PodcastService.AppLinksByPodcastID(props.podcast.id)
			form.spotifyURL = applinks.find(al => al.app === "Spotify").url
			form.appleURL = applinks.find(al => al.app === "Apple Podcasts").url
			form.googleURL = applinks.find(al => al.app === "Google Podcasts").url
			form.castboxURL = applinks.find(al => al.app === "Castbox").url
			form.podchaserURL = applinks.find(al => al.app === "Podchaser").url
			form.tuneinURL = applinks.find(al => al.app === "TuneIn").url
			form.overcastURL = applinks.find(al => al.app === "Overcast").url
			form.podcastaddictURL = applinks.find(al => al.app === "Podcast Addict").url
			form.stitcherURL = applinks.find(al => al.app === "Stitcher").url
		})

		const closeModal = () => {
			emit("close")
			error.value = ""
			deleteError.value = ""
		}

		const handleDelete = async () => {
			await deletePodcast(props.podcast.id)
			if (!deleteError.value) router.go(0)
		}

		const handleSubmit = async () => {
			if (form.podcastDomain.length < 5) {
				error.value = "Invalid Podcast Domain"
				return
			}
			if (form.podcastRSS.length < 5) {
				error.value = "Invalid RSS Link"
				return
			}
			
			await updatePodcast(new Podcast({
				rss: form.podcastRSS, 
				domain: form.podcastDomain,
				podcastID: props.podcast.id,
			}),
				[{app: "Spotify", url: form.spotifyURL },
				{app: "Apple Podcasts", url: form.appleURL },
				{app: "Google Podcasts", url: form.googleURL },
				{app: "Castbox", url: form.castboxURL },
				{app: "podchaser", url: form.podchaserURL },
				{app: "tunein", url: form.tuneinURL },
				{app: "Overcast", url: form.overcastURL },
				{app: "Podcast Addict", url: form.podcastaddictURL },
				{app: "Stitcher", url: form.stitcherURL }],
			)
			if (!error.value) router.go(0)
		}
		
		return { 
			closeModal, handleSubmit, handleDelete, deleteError, deleteLoading, form, loading, error 
		}
	}

}
</script>

<style>

</style>