import { ref } from "vue"
import { projectAuth } from "../../lib/firebase/config"

const error = ref(null)
const loading = ref(null)

const logout = async () => {
	loading.value = true
	try {
		await projectAuth.signOut()
		error.value = ""
		loading.value = false
	} catch (err) {
		console.log(err)
		error.value = err.message
		loading.value = false
	}
}

const useLogout = () => {
	return { error, loading, logout }
}

export default useLogout