import { ref } from "vue"
import { sleep } from "../../lib/util/util.js"
import PodcastService from "../../services/PodcastService"

const error = ref(null)
const loading = ref(false)

const deletePodcast = async (podcastID) => {
	loading.value = true
	await sleep(1000)
	try {
		await PodcastService.Delete(podcastID)
		error.value = null
		loading.value = false
	} catch (err) {		
		if (err.message.includes("foreign key error")) error.value = "Failed to delete Podcast! Please delete all players of this Podcast!"
		else error.value = "Failed to delete Podcast!"
		loading.value = false
	}
}

const useDeletePodcast = () => {
	return { error, loading, deletePodcast }
}

export default useDeletePodcast