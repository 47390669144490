class Episode {
	constructor({ guid, number, title }) {
		this.guid = guid
		this.number = number
		this.title = title
	}

	get shortTitle() {
		if (this.title.length > 65) return this.title.substring(0,65) + "..."
		return this.title
	}

	titleContainsNumber() {
		return this.title.includes(this.number)
	}
}

export default Episode