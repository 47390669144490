<template>
	<div class="min-h-screen bg-white flex">
		<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			<img src="@/assets/podplayer-icon-green.png" alt="" class="h-auto w-24 -ml-2">
			<div class="mx-auto w-full max-w-sm lg:w-96">
				<!-- SIGNUP FORM HEADER -->
				<h2 class="mt-6 text-3xl font-extrabold text-gray-900">
					Login to your account!
				</h2>
				<p class="mt-2 text-gray-600">
					Or
					<router-link :to="{ name: 'Signup' }" class="font-medium text-green-600 hover:text-green-500">
						Sign up for Podplayer.co
					</router-link>
				</p>			

				<!-- FORM -->
				<form @submit.prevent="handleSubmit" class="space-y-6 mt-8">
					<div>
						<label class="block text-sm font-medium text-gray-700">
							Email address
						</label>
						<div class="mt-1">
							<input type="email" v-model="email" autocomplete="email" required 
							class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md 
							shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 
							focus:border-green-500" />
						</div>
					</div>				

					<div class="space-y-1">
						<label class="block text-sm font-medium text-gray-700">
							Password
						</label>
						<div class="mt-1">
							<input type="password" v-model="password" autocomplete="current-password" required 
							class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md 
							shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 
							focus:border-green-500 sm:" />
						</div>
					</div>							

					<div class="flex items-center justify-center h-12">
						<button type="submit" v-if="!loading"
						class="w-full py-2 px-4 border border-transparent rounded-md shadow-sm font-medium 
						text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 
						focus:ring-offset-2 focus:ring-green-600">
							Sign in
						</button>
						<Spinner v-if="loading"/>
					</div>
				</form>
				<!-- ERROR CARD -->				
				<ErrorCard v-if="error">
					{{ error }}
				</ErrorCard>			
			</div>
		</div>
		<!-- BACKGROUND IMAGE -->
		<div class="hidden lg:block relative w-0 flex-1">
			<img src="@/assets/headphones.jpg"
			class="absolute inset-0 h-full w-full object-cover"/>
		</div>
	</div>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"
import useLogin from "../hooks/auth/useLogin.js"
import ErrorCard from "@/components/ErrorCard"
import Navigation from "@/components/Navigation"
import Spinner from "@/components/Spinner"

export default {
	components: { ErrorCard, Spinner, Navigation },
	setup() {
		const email    = ref("")
		const password = ref("")

		const router = useRouter()

		const { login, loading, error } = useLogin()

		async function handleSubmit() {
			await login(email.value, password.value)
			if (!error.value) router.push({ name: "Dashboard" })
		}

		return { email, password, handleSubmit, error, loading }
	}
}
</script>