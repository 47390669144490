<template>
<div class="rounded-lg border border-gray-200 h-32 p-3 shadow-lg flex items-center 
cursor-pointer hover:border-gray-500">
	<div class="h-24 w-24 shadow-md rounded mr-5 overflow-hidden">
		<img :src="player.artworkURL"
		class="object-cover filter saturate-125">
	</div>
	<div>
		<h3 class="text-lg">{{ player.shortName }}</h3>
		<p class="text-sm text-gray-500">{{ player.typeToString }} Player </p>
		<p class="text-gray-400 text-xs mt-1">(click to edit or embed)</p>
	</div>
</div>
</template>

<script>
export default {
	props: ["player"]
}
</script>

<style>

</style>