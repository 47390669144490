import client from "@/lib/axios/config.js"
import Episode from "../classes/Episode"

async function Latest(episodeCount, podcastID) {
	try {
		console.log(podcastID)
		const res = await client.get(`/client/episodes/latest/${podcastID}/${episodeCount}`)
		const episodes = []
		res.data.forEach(ep => episodes.push(new Episode({
			title: ep.title,
			guid: ep.guid,
			number: ep.number,
		})))
		return episodes
	} catch (err) {
		throw new Error(err.response.data)
	}
}

async function SearchByTitle(titleSearchString, podcastID) {
	try {
		const res = await client.get(`/client/episodes/search/title/${podcastID}/${titleSearchString}`)
		const episodes = []
		res.data.forEach(ep => episodes.push(new Episode({
			title: ep.title,
			guid: ep.guid,
			number: ep.number,
		})))
		return episodes
	} catch (err) {
		throw new Error(err.response.data)
	}
}

export default { Latest, SearchByTitle }