<template>
	<!-- <div id="nav">
		<router-link to="/signup">Signup</router-link> |
		<router-link to="/login">Login</router-link>
	</div> -->
	<router-view/>
</template>

<style>
	body {
		background-color: #fff;
	}
	
</style>
