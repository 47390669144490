import { ref } from "vue"
import { sleep } from "../../lib/util/util.js"
import PodcastService from "../../services/PodcastService"

const error = ref(null)
const loading = ref(false)

const updatePodcast = async (podcast, applinks) => {
	loading.value = true
	await sleep(1000)
	try {
		await PodcastService.Update(podcast)
		applinks.forEach((_, idx) => {
			applinks[idx].podcastID = podcast.id
		})
		await PodcastService.SetAppLinks(applinks)
		error.value = null
		loading.value = false
	} catch (err) {
		error.value = err.message
		loading.value = false
	}
}

const useUpdatePodcast = () => {
	return { error, loading, updatePodcast }
}

export default useUpdatePodcast