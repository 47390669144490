<template>
	<Navigation />
	<Banners />
	<div class="ml-28 px-20 py-12">
		<PageHeading>Dashboard <span v-if="user.isPaidUser()" class="font-light text-gray-700">({{ user.roleString }})</span></PageHeading>
		<h3 class="text-2xl mb-8 leading-6 font-bold text-gray-900">
			Watch the Podplayer Quickstart Tutorial!
		</h3>
		<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/KgiE9wh4haU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>	
	</div>

</template>

<script>
import { useRoute } from "vue-router"
import Navigation from "../components/Navigation.vue"
import Banners from "../components/Banners.vue"
import PageHeading from "../components/headings/PageHeading.vue"
import getUser from '../hooks/getUser'
import { sleep } from '../lib/util/util'

export default {
	components: { Navigation, Banners, PageHeading },
	setup() {
		const { user } = getUser()
		const route = useRoute()
		if (route.query.forcetokenrefresh == "true") {
			;(async () => {
				await sleep(2000)
				user.value.forceTokenRefresh()
			})()
		}
		return {	user }
	},
}
</script>