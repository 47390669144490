import { ref } from "vue"
import { projectAuth } from "../lib/firebase/config"
import User from "../classes/User"

const user = ref(new User({ firebaseUser: projectAuth.currentUser }))

projectAuth.onIdTokenChanged(async (_user) => {
	console.log("User state changed: Current user:", _user)
	user.value.firebaseUser = _user
	if (_user) {
		const idTokenResult = await _user.getIdTokenResult()
		user.value.role = idTokenResult.claims.role
		user.value.currentPeriodEnd = idTokenResult.claims.currentPeriodEnd
		console.log("Claims:", user.value)
	}
})

const getUser = () => {
	return { user }
}

export default getUser