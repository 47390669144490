<template>
<Navigation />
<Banners />
<AddPodcastModal :show="addPodcastModal.show" @close="addPodcastModal.close" />
<EditPodcastModal :show="editPodcastModal.show" @close="editPodcastModal.close" :podcast="podcastToEdit" />
<div class="ml-28 px-20 py-12 max-w-screen-2xl">
	<PageHeading>Podcasts <span v-if="user.isPaidUser()" class="font-light text-gray-700">({{ user.roleString }})</span></PageHeading>	
	<div class="pb-5 mb-8 border-b border-gray-200">
		<h3 class="text-2xl mb-5 leading-6 font-bold text-gray-900">
			Your added shows ({{ userPodcasts.length }}/{{ user.maxPodcasts }})
		</h3>
		<p class="mt-2 max-w-4xl text-gray-500">On this page you can add your podcast(s)! Once you have added a podcast to this list, you can create embeddable players for it in the "Players" menu! To add a podcast just click the big '<b class="text-lg">+</b> <b>Add Podcast</b>' button at the bottom of this page!</p>
		<p class="mt-2 max-w-4xl text-gray-500">To update a podcasts details, just click on the podcast itself, and a popup will show, where you can make necessary changes!</p>
		<p class="mt-2 max-w-4xl text-gray-500"><b>Important:</b> The amount of shows that you can add depends on your account level. If you need to add more podcasts, consider upgrading your account!</p>

	</div>

	<div v-if="!loading" class="grid grid-cols-2 gap-4">
		<PodcastCard v-for="p in userPodcasts" :key="p.id" :podcast="p" 
		@click="setPodcastToEdit(p); editPodcastModal.open()" />
	</div>
	<Spinner v-if="loading"/>

	<div @click="handleAddModalOpen" v-if="!loading"
	class="w-48 h-20 mt-12 text-white space-x-2 rounded-md ml-1 bg-green-600 flex items-center justify-center cursor-pointer hover:bg-green-700">
		<p class="text-3xl font-bold pb-1">+</p>
		<p class="text-lg">Add Podcast</p>
	</div>
	
</div>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"
import Navigation from "../components/Navigation.vue"
import Spinner from "../components/Spinner.vue"
import PodcastCard from "../components/PodcastCard.vue"
import BaseModal from "../components/base/BaseModal.vue"
import AddPodcastModal from "../components/AddPodcastModal.vue"
import EditPodcastModal from "../components/EditPodcastModal.vue"
import useGetPodcasts from "../hooks/podcast/useGetPodcasts"
import getUser from '../hooks/getUser'
import useModal from "../hooks/useModal"
import Banners from "../components/Banners.vue"
import PageHeading from "../components/headings/PageHeading.vue"

export default {
	components: { Navigation, PodcastCard, BaseModal, AddPodcastModal, EditPodcastModal, Spinner, Banners, PageHeading },
	setup() {
		const router = useRouter()
		const { user } = getUser()
		const { podcasts: userPodcasts, error, loading, getPodcasts } = useGetPodcasts()		
		getPodcasts()

		const podcastToEdit = ref(null)
		const setPodcastToEdit = p => podcastToEdit.value = p

		const addPodcastModal = useModal()
		const editPodcastModal = useModal()
		const handleAddModalOpen = () => {
			if (userPodcasts.value.length >= user.value.maxPodcasts) {
				if (user.value.isProUser()) return // (weil im Moment noch kein höherer Plan verfügbar ist)
				const upgradePageTitle = "You have reached your Show limit! But don't worry:"
				router.push(`/upgrade-plan?title=${upgradePageTitle}`)
				return
			}
			addPodcastModal.open()
		}

		return { user, userPodcasts, error, loading, addPodcastModal, handleAddModalOpen, 
		editPodcastModal, setPodcastToEdit, podcastToEdit }
	},
}
</script>