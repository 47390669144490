<template>
<div class="rounded-lg border border-gray-200 h-32 p-3 shadow-lg flex items-center 
cursor-pointer hover:border-gray-500">
	<div class="h-24 w-24 shadow-md rounded mr-5 overflow-hidden">
		<img :src="podcast.artworkURL"
		class="object-cover">
	</div>
	<div>
		<h3 class="text-lg">{{ podcast.shortName }}</h3>
		<p>{{ podcast.domain }}</p>
		<p class="text-gray-400 text-xs mt-1">(click to edit)</p>
	</div>
</div>
</template>

<script>
export default {
	props: ["podcast"],

}
</script>

<style>

</style>