<template>
	<form @submit.prevent>
		<select class="cursor-pointer rounded-lg w-full" @change="handleChange">
			<option v-for="item in items" :key="itemKeyCallback(item)" :value="itemKeyCallback(item)"
			class="cursor-pointer">
				{{ itemValueCallback(item) }}
			</option>
		</select>
	</form>
</template>

<script>
export default {
	props: ["items", "itemValueCallback", "itemKeyCallback"],
	emits: ["change"],
	setup(props, { emit }) {
		function handleChange(ev) {
			const selectedIdx = props.items.findIndex(
				item => props.itemKeyCallback(item) == ev.target.value
			)
			console.log(selectedIdx)
			emit("change", selectedIdx)
		}
		return { handleChange }
	}
}
</script>

<style>

</style>