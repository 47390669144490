export function sleep(ms) {
	return new Promise(resolve => {
		setTimeout(resolve, ms)
	})
}

export function copyTextToClipboard(text) {
	navigator.clipboard.writeText(text)
}

export function injectScriptIntoDOM(parentElement, scriptString) {
	const newScript = document.createElement("script")
}