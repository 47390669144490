<template>
	
		<div class="max-w-7xl pt-12 divide-y divide-gray-200 lg:pt-16">
			<h2 class="text-2xl font-bold text-gray-900">
				Frequently asked questions (FAQ)
			</h2>
			<div class="mt-8">
			<dl class="divide-y divide-gray-200">
				<div v-for="faq in faqs" :key="faq.id" class="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
					<dt class="text-base font-medium text-gray-900 md:col-span-5">
					{{ faq.question }}
					</dt>
					<dd class="mt-2 md:mt-0 md:col-span-7">
					<p class="text-base text-gray-500" v-html="faq.answer"></p>
					</dd>
				</div>
			</dl>
			</div>
		</div>
	
</template>

<script>
const faqs = [
	{
		id: 1,
		question: "I am confused how to create a Podcast Player!",
		answer: "No problem, that's why we have created an all-in-one video tutorial, which is about 15 minutes long, and teaches you everything you need to know about working with Podplayer! Click here to watch the video: <a class='underline font-semibold hover:text-gray-900' href='#tutorial-videos'>app.podplayer.co/video-tutorials</a>",
	}, {
		id: 2,
		question: "How can I report a bug?",
		answer: "If you found a bug in the Podplayer software (bug = something not working as intended), please send us an email to <a class='underline font-semibold hover:text-gray-900' href='mailto:support@podplayer.co'>support@podplayer.co</a>, and let us know about it. We will fix the bug as soon as possible!",
	}, {
		id: 3,
		question: "How to change my account plan?",
		answer: "If you want to upgrade from FREE to PRO plan, just click this link: <a class='underline font-semibold hover:text-gray-900' href='/upgrade-plan'>app.podplayer.co/upgrade-plan</a>",
	}, {
		id: 4,
		question: "Why does Podplayer not have Feature X?",
		answer: "If you have a cool idea for a feature that you want Podplayer to have, just send us en email to <a class='underline font-semibold hover:text-gray-900' href='mailto:support@podplayer.co'>support@podplayer.co</a>. If it is a good idea we will most likely implement it!",
	}, {
		id: 5,
		question: "How to contact the Podplayer support?",
		answer: "Just send us an email to <a class='underline font-semibold hover:text-gray-900' href='mailto:support@podplayer.co'>support@podplayer.co</a> and we will reply within 1-2 working days.",
	}, {
		id: 6,
		question: "How to cancel my Podplayer PRO subscription?",
		answer: "Send an email to <a class='underline font-semibold hover:text-gray-900' href='mailto:support@podplayer.co'>support@podplayer.co</a> and we will reply and cancel your subscription within 1-2 working days.",
	},

	// More questions...
]

export default {
  setup() {
    return {
      faqs,
    }
  },
}
</script>
