<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<div class="bg-white">
		<div class="max-w-6xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
			<div class="sm:flex sm:flex-col sm:align-center">
			<h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Select your Podplayer.co plan!</h1>
			<p class="mt-5 text-xl text-gray-500 sm:text-center">Choose the plan & billing cycle which fits best to your needs</p>
			<div v-if="monthlyBilling" class="relative self-center mt-6 bg-gray-200 rounded-lg p-1 flex sm:mt-8">
				<button type="button" @click="monthlyBilling = false"
				class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-md font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">
					<span>Yearly billing</span>
					<p class="text-xs text-gray-400">(click to see prices)</p>		
				</button>
				<button type="button" @click="monthlyBilling = true"
				class="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-md font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">
				Monthly biling
				</button>
			</div>
			<div v-else class="relative self-center mt-6 bg-gray-200 rounded-lg p-1 flex sm:mt-8">
				<button type="button" @click="monthlyBilling = false"
				class="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-md font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Yearly billing</button>
				<button type="button" @click="monthlyBilling = true"
				class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-md font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">
					<span>Monthly billing</span>
					<p class="text-xs text-gray-400">(click to see prices)</p>				
				</button>				
			</div>
			</div>
			<div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-3xl lg:mx-auto xl:max-w-4xl">
			<div v-for="plan in plans" :key="plan.name" class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
				<div class="p-6">
					<h2 class="text-2xl leading-6 font-semibold text-gray-900">{{ plan.name }}</h2>
					<p class="mt-4 text-sm text-gray-500">{{ plan.description }}</p>
					<div class="mt-8 flex space-x-3 items-center">
					<span v-if="monthlyBilling" class="text-4xl font-extrabold text-gray-900">${{ plan.priceMonthly }}</span>{{ ' ' }}
					<span v-if="!monthlyBilling" class="text-4xl font-extrabold text-gray-900">${{ plan.priceMonthlyYear }}</span>{{ ' ' }}
					<div>
						<p class="text-base font-medium text-gray-600">USD / month</p>{{ ' ' }}
						<div v-if="!(plan.name == 'Free')">
							<p v-if="!monthlyBilling" class="text-sm text-gray-500">Billed yearly (${{ 12 * plan.priceMonthlyYear }})</p>
							<p v-else class="text-sm text-gray-500">Billed every month</p>
						</div>						
					</div>
					</div>
					<a v-if="plan.name == 'Business'" @click="handlePlanSelection(plan.name)" class="mt-8 cursor-pointer block w-full bg-indigo-600 border border-indigo-600 rounded-md py-2 text-md font-semibold text-white text-center hover:bg-indigo-700">Buy {{ plan.name }}</a>
					<a v-else @click="handlePlanSelection(plan.name)" class="mt-8 cursor-pointer block w-full bg-gray-700 border border-gray-700 rounded-md py-2 text-md font-semibold text-white text-center hover:bg-gray-800" :class="{ 'bg-green-600': plan.name == 'Pro', 'hover:bg-green-700': plan.name == 'Pro' }">
						<span v-if="plan.name == 'Free'">Get started</span>
						<span v-else>Buy {{ plan.name }}</span>
					</a>
				</div>
				<div class="pt-6 pb-8 px-6">
					<h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
					<ul role="list" class="mt-6 space-y-4">
					<li v-for="feature in plan.includedFeatures" :key="feature" class="flex space-x-3">
						<CheckIcon class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
						<span class="text-base text-gray-500">{{ feature }}</span>
					</li>
					</ul>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"
import StripeService from "../services/StripeService"
import { CheckIcon } from '@heroicons/vue/solid'
import { sleep } from "../lib/util/util.js"
import getUser from "../hooks/getUser"

const plans = [	
	{
		idMonthly: 2,
		idYearly: 3,
		name: 'Pro',
		href: '#',
		priceMonthly: 12,
		priceMonthlyYear: 9,
		description: 'All the basics for starting a new business',
		includedFeatures: [
			'Create unlimited players for 3 different podcasts', 
			'Remove "Powered by Podplayer.co" Branding',
			'Access to Single-Player',
			'Access to Latest-Player',
			'Access to Archive-Player (coming soon)',
			'up to 100.000 pageviews per month',
			'Get priority support',
		],
	},  
	{
		idMonthly: 1,
		idYearly: 1,
		name: 'Free',
		href: '#',
		priceMonthly: 0,
		priceMonthlyYear: 0,
		description: 'All the basics for starting a new business',
		includedFeatures: [
			'Create 2 players for one podcast show', 
			'up to 5.000 pageviews per month',
			'Access to Single-Player',
			'Standard support',			
		],
	},
]

export default {
	components: {	CheckIcon },	
	setup() {
		const monthlyBilling = ref(false)
		const router = useRouter()

		const { user } = getUser()

		async function handlePlanSelection(planName) {
			if (user.value.isPaidUser()) {
				const portalLink = await StripeService.CreatePortalSession()
				window.location.href = portalLink
				return
			}
			if (planName == "Free") {
				await sleep(1000)
				router.push({ name: "Dashboard" })
				return
			}
			const selectedPlan = plans.find(plan => plan.name == planName)
			const planID = monthlyBilling.value ? selectedPlan.idMonthly : selectedPlan.idYearly
			const checkoutLink = await StripeService.CreateCheckoutSession(planID)
			window.location.href = checkoutLink
		}

		return {	user, plans, monthlyBilling, handlePlanSelection }
	},
}
</script>