import { ref } from "vue"
import { sleep } from "../../lib/util/util.js"
import EpisodeService from "../../services/EpisodeService"

const error = ref(null)
const loading = ref(false)
const searchString = ref("")
const episodes = ref([])

const searchEpisodesByTitle = async (podcastID) => {
	loading.value = true
	await sleep(1000)
	try {
		episodes.value = await EpisodeService.SearchByTitle(searchString.value, podcastID)
		error.value = null
		loading.value = false
	} catch (err) {
		error.value = err.message
		loading.value = false
	}
}

const clearSearchedEpisodes = () => {
	searchString.value = ""
	episodes.value = []
}

const useSearchEpisodesByTitle = () => {
	return { error, loading, episodes, searchString, searchEpisodesByTitle, clearSearchedEpisodes }
}

export default useSearchEpisodesByTitle