<template>
	<ActivateEmailBanner />
</template>

<script>
import ActivateEmailBanner from "./banners/ActivateEmailBanner.vue"

export default {
	components: { ActivateEmailBanner },
}
</script>

<style>

</style>