class Podcast {
	constructor({ podcastID, name, rss, domain, userID, artworkURL }) {
		this.id = podcastID
		this.name = name
		this.rss = rss
		this.domain = domain
		this.userID = userID
		this.artworkURL = artworkURL
	}

	get shortName() {
		if (this.name.length < 35) return this.name
		return this.name.slice(0, 35) + " ..."
	}
}

export default Podcast