<template>
<BaseModal @close="closeModal" :show="show" :twClasses="'w-11/12 md:w-200'">
	<h2 class="text-gray-900 text-3xl font-bold">
		Add a new Podcast!
	</h2>

	<!-- RSS FIELD -->
	<TextInput v-model="form.podcastRSS"
	:label="'Link to your RSS Feed'" :placeholder="'https://feeds.yourpodcasthoster.com/abcdef123.rss'">
		<template v-slot:subtext>Watch video: <a href="#" class="underline hover:text-gray-800">Where to find my Podcasts RSS-Link?</a></template>
	</TextInput>

	<!-- DOMAIN FIELD -->
	<TextInput v-model="form.podcastDomain"
	:label="'Domain'" :placeholder="'www.yourwebsite.com'">
		<template v-slot:subtext>Enter your website's domain, where you will use the Podcast Player!</template>
	</TextInput>

	<h3 class="text-2xl font-bold mt-5">Applinks</h3>
	<p class="mt-2 text-sm text-gray-500" id="email-description">Watch video: <a href="#" class="underline hover:text-gray-800">What are Applinks and where to find them?</a></p>

	<div class="grid grid-cols-2 gap-x-3">
		<TextInput v-model="form.spotifyURL" :label="'Spotify'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.appleURL" :label="'Apple Podcasts'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.googleURL" :label="'Google Podcasts'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.castboxURL" :label="'Castbox'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.podchaserURL" :label="'Podchaser'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.tuneinURL" :label="'TuneIn + Alexa'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.overcastURL" :label="'Overcast'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.podcastaddictURL" :label="'Podcast Addict'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
		<TextInput v-model="form.stitcherURL" :label="'Stitcher'" :placeholder="'www.linktopodcastapp.com/showid-abc123'"></TextInput>	
	</div>
	
	<div class="inline-flex justify-center items-center h-12 mt-8 mb-2">
		<button v-if="!loading" @click="handleSubmit"
		class="p-5 rounded bg-green-600 text-white hover:bg-green-700">
			Add Podcast!
		</button>
		<Spinner v-if="loading"/>
	</div>
	<ErrorCard v-if="!loading && error">
		{{ error }}
	</ErrorCard>

	
	
</BaseModal>
</template>

<script>
import { reactive } from "vue"
import { useRouter } from "vue-router"
import useCreatePodcast from "../hooks/podcast/useCreatePodcast"
import BaseModal from "./base/BaseModal.vue"
import Spinner from "../components/Spinner.vue"
import Podcast from '../classes/Podcast'
import ErrorCard from "../components/ErrorCard.vue"
import TextInput from "../components/TextInput.vue"

export default {
	components: { BaseModal, Spinner, ErrorCard, TextInput },
	props: ["show"],
	setup(_, { emit }) {
		const router = useRouter()
		const { error, loading, createPodcast } = useCreatePodcast()
		const form = reactive({
			podcastDomain: "", 
			podcastRSS: "",
			spotifyURL: "",
			appleURL: "",
			googleURL: "",
			castboxURL: "",
			podchaserURL: "",
			tuneinURL: "",
			overcastURL: "",
			podcastaddictURL: "",
			stitcherURL: "",
		})
		const closeModal = () => {
			emit("close")
			error.value = ""
		}

		const handleSubmit = async () => {			
			if (form.podcastDomain.length < 5) {
				error.value = "Invalid Podcast Domain"
				return
			}
			if (form.podcastRSS.length < 5) {
				error.value = "Invalid RSS Link"
				return
			}
			await createPodcast(
				new Podcast({rss: form.podcastRSS, domain: form.podcastDomain}),
				[{app: "Spotify", url: form.spotifyURL },
				{app: "Apple Podcasts", url: form.appleURL },
				{app: "Google Podcasts", url: form.googleURL },
				{app: "Castbox", url: form.castboxURL },
				{app: "Podchaser", url: form.podchaserURL },
				{app: "TuneIn", url: form.tuneinURL },
				{app: "Overcast", url: form.overcastURL },
				{app: "Podcast Addict", url: form.podcastaddictURL },
				{app: "Stitcher", url: form.stitcherURL }],
			)
			
			if (!error.value) router.go(0)
		}
		
		return { closeModal, handleSubmit, form, loading, error }
	}

}
</script>

<style>

</style>