import { ref } from "vue"
import { sleep } from "../../lib/util/util.js"
import PlayerService from "../../services/PlayerService"

const error = ref(null)
const loading = ref(false)

const deletePlayer = async (playerID) => {
	loading.value = true
	await sleep(1000)
	try {
		await PlayerService.Delete(playerID)
		error.value = null
		loading.value = false
	} catch (err) {		
		error.value = err.message
		loading.value = false
	}
}

const useDeletePlayer = () => {
	return { error, loading, deletePlayer }
}

export default useDeletePlayer