import { ref } from "vue"
import { projectAuth } from "../../lib/firebase/config"

const error = ref(null)
const loading = ref(false)

const login = async (email, password) => {
	loading.value = true
	try {
		const res = await projectAuth.signInWithEmailAndPassword(email, password)
		if (!res) throw new Error("Login did not work!")
		error.value = null
		console.log(res)
		loading.value = false
		return res
	} catch (err) {
		console.log(err.message)
		error.value = "Incorrect login credentials!"
		loading.value = false
	}
}

const useLogin = () => {
	return { error, loading, login }
}

export default useLogin