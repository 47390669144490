import { createRouter, createWebHistory } from 'vue-router'
import { projectAuth } from '../lib/firebase/config'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Dashboard from '../views/Dashboard.vue'
import Settings from '../views/Settings.vue'
import Players from '../views/Players.vue'
import Podcasts from '../views/Podcasts.vue'
import ChoosePlan from '../views/ChoosePlan.vue'
import UpgradePlan from '../views/UpgradePlan.vue'
import Help from '../views/Help.vue'

// auth gard
const requireAuth = (to, from, next) => {
	const user = projectAuth.currentUser
	console.log("Current user in auth guard:", user)
	if (!user) {
		next({ name: "Login", path: "/login" })
		return
	}
	next()
}

const routes = [
	{ path: '/', redirect: { name: 'Dashboard' } },
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/signup',
		name: 'Signup',
		component: Signup
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		component: Dashboard,
		beforeEnter: requireAuth,
	},
	{
		path: "/choose-plan",
		name: "ChoosePlan",
		component: ChoosePlan,
		beforeEnter: requireAuth,
	},
	{
		path: "/upgrade-plan",
		name: "UpgradePlan",
		component: UpgradePlan,
		beforeEnter: requireAuth,
	},
	{
		path: "/settings",
		name: "Settings",
		component: Settings,
		beforeEnter: requireAuth,
	},
	{
		path: "/podcasts",
		name: "Podcasts",
		component: Podcasts,
		beforeEnter: requireAuth,
	},
	{
		path: "/players",
		name: "Players",
		component: Players,
		beforeEnter: requireAuth,
	},
	{
		path: "/help",
		name: "Help",
		component: Help,
		beforeEnter: requireAuth,
	}
]

const router = createRouter({
	routes,
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { el: to.hash }
		}
	},	
})

export default router
