import { ref } from "vue"
import { sleep } from "../../lib/util/util.js"
import PodcastService from "../../services/PodcastService"

const error = ref(null)
const loading = ref(false)
const podcasts = ref([])

const getPodcasts = async () => {
	loading.value = true
	await sleep(1000)
	try {
		podcasts.value = await PodcastService.ByUser()
		error.value = null
		loading.value = false
	} catch (err) {
		error.value = err.message
		loading.value = false
	}
}

const useGetPodcasts = () => {
	return { error, loading, podcasts, getPodcasts }
}

export default useGetPodcasts