import { ref } from "vue"
import UserService from "../../services/UserService"
import { projectAuth } from "../../lib/firebase/config"
import { sleep } from "../../lib/util/util"
import { forceTokenRefresh } from "../../lib/firebase/util"

const error = ref(null)
const loading = ref(false)

const signup = async (email, password, name) => {
	loading.value = true
	try {
		await UserService.create(email, password, name)
		await projectAuth.currentUser.sendEmailVerification()
		await forceTokenRefresh(projectAuth.currentUser) // damit claims geladen werden
		error.value = null
		loading.value = false
	} catch (err) {
		console.log(err.message)
		error.value = err.message
		loading.value = false
	}
}

const useSignup = () => {
	return { error, signup, loading }
}

export default useSignup